import React from 'react'
import './GraphiquePrint.css'
import { PrintCarddata } from '../../Data/dataimage'
import Slideshow from '../../composants/Slide/Slide'
import Mockup from '../../assets/img/mokup-ornoir 1.png'
import mockup_carte_de_visite from '../../assets/img/mock_up_06 1.png'
import PrintCard from '../../composants/PrintCard/PrintCard'

import ArrowRight from '../../assets/icons_btn/Arrow_right.svg'
import ArrowLeft from '../../assets/icons_btn/Arrow_left.svg'
import DemandeDevis from '../../composants/DemandeDevis/DemandeDevis'


const GraphiquePrint = ({Deviser}) => {
  return (
    <>

      <main>

        <section className='GP container'>
          <Slideshow></Slideshow>
        </section>
        <section className='container'>

          <div id='GPslide1'>

            <div className='bloc'>
              <h1 className='heading1 yellow'>Des cartes personnalisées qui reflètent votre professionnalisme.</h1>

              <p>
                Besoin d’aide pour personnaliser l’un de nos produits ? Partagez vos idées et recevez un graphisme créé par l’un de nos experts.
              </p>

            </div>

            <div className='bloc'>
              <img src={Mockup} alt="Mockup" />
            </div>

          </div>

          <div id='CTA'>
           <DemandeDevis Deviser={Deviser}/>

            <span style={{fontStyle:'italic'}}>
              Nous vous rappelons en moins de 5 heures
            </span>

          </div>

        </section>

        <section className='container GPsect3'>

          <div>
            <h1 className='heading1 yellow'>
            Une Infinité de possibilité pour votre image de marque
            </h1>
          </div>
          <div>
            <p>
            Nos services de concepption et d’impression en ligne de <span style={{fontWeight:'900'}}>Ornoir Agency</span> dirigeants de TPE-PME à promouvoir leurs activités professionnelles avec des supports imprimés de haute qualité, des outils de marketing numériques et un service de conception graphique. Notre site web a été conçu pour vous aider à trouver les produits personnalisés dont vous avez besoin pour créer une image de marque qui vous ressemble et atteindre vos objectifs
            </p>
          </div>

        </section>

        <section className='container GPsect4'>
          <span className='heading_span yellow'>
            Meilleures outils print papier
          </span>
          <div id='GPsect4div'>

            <div id='ArrowLeft' style={{visibility:'hidden'}}>
              <img src={ArrowLeft} alt="Left" />
            </div>

            <PrintCard src={PrintCarddata.entete_lettre} alt={"entete de lettre"} titre={'Papier entête lettre'}/>
            <PrintCard src={PrintCarddata.Flyers} alt='Flyers' titre='Flyers'/>
            <PrintCard src={PrintCarddata.chemiserabat} alt={"Chemise à rabat"} titre={'Chemise à rabat'}/>
            <PrintCard src={PrintCarddata.depliant} alt={"Dépliants"} titre={'Dépliants'}/>
           
           <div id='ArrowRight' style={{visibility:'hidden'}}>
              <img src={ArrowRight} alt="Right" />
            </div>

          </div>
        </section>

        <section className='container GPsect5'>
          <span className='heading_span yellow'>
             Vos outils de communication corporate
          </span >
          <div id='GPsect5div'>
            <PrintCard src={PrintCarddata.entete_lettre} alt={"entete de lettre"} titre={'Papier entête lettre'}/>
            <PrintCard src={PrintCarddata.badge_acces} alt={"Badge d'acces"} titre={'Badge d\'accès'}/>
            <PrintCard src={PrintCarddata.chemiserabat} alt={"Chemise à rabat"} titre={'Chemise à rabat'}/>
            <PrintCard src={PrintCarddata.blocnote} alt={"Bloc notes"} titre={'Bloc notes'}/>
            <PrintCard src={PrintCarddata.cartedevisitepaysage} alt={"Cartes de visite   Format Paysage"} titre={'Cartes de visite \n Format Paysage'}/>
            <PrintCard src={PrintCarddata.cartedevisiteportrait} alt={"Cartes de visite \n Format Portrait"} titre={'Cartes de visite Format Portrait'}/>
            <PrintCard src={PrintCarddata.cartedevisitecarre} alt={"Cartes de visite  \n Format Carré"} titre={'Cartes de visite Format Carré'}/>
            <PrintCard src={PrintCarddata.cartedevisiterectangle} alt={"Cartes de visite  \n Format Rectangulaire"} titre={'Cartes de visite Format Rectangulaire'}/>
            <PrintCard src={PrintCarddata.polotshirt} alt={"Polo & T-shirt"} titre={'Polo & T-shirt'}/>
            <PrintCard src={PrintCarddata.kakemono} alt={"Kakemono (Roll-up)"} titre={'Kakemono  \n (Roll-up)'}/>
            <PrintCard src={PrintCarddata.affiche} alt={"Affiche A2 & A3"} titre={'Affiche A2 & A3'}/>
            <PrintCard src={PrintCarddata.depliant} alt={"Dépliants"} titre={'Dépliants'}/>


          </div>
        </section>
      
        <section className="container  yellow GPsect6">
          <div id='GPsect6div'>

            <div className='bloc'>

              <span className='heading_span'>Une carte de visite professionnelle</span>

              <h1 className='heading1'>un excellent <br /> moyen de faire bonne impression</h1>

              <p>
              Pour attirer des clients, il faut d'abord qu'ils vous remarquent. Démarquez-vous à l’aide de cartes de visite qui reflètent votre professionnalisme, et distribuez-les lors d’évènements professionnels, utilisez-les pour vos rendez-vous ou ajoutez-les aux achats des clients.
              </p>

            </div>
           
            <div className='bloc'>
              <img src={mockup_carte_de_visite} alt="Carte de visite"  style={{width:'100%'}}/>
            </div>

          </div>
        </section>
        
      </main>

    </>
    
  )
}

export default GraphiquePrint