import React from 'react'
import './WebDev.css'
import template_site from '../../assets/img/Template_site.png'
import notation from '../../assets/img/Notation.png'
import roster_sites from '../../assets/img/Roster_site.png'
import Figma_logo from '../../assets/img/Figma_logo.png'
import puce_spe from '../../assets/icons/Puce_spe.svg'
import site1 from '../../assets/img/Site1.png'
import site2 from '../../assets/img/Site2.png'
import site3 from '../../assets/img/Site3.png'
import site4 from '../../assets/img/Site4.png'
import dev_sol_anime from '../../assets/img/dev_anime.png'
import newsletter_img from '../../assets/img/newsletter_img.png'

import html_logo from '../../assets/img/Html_logo.png'
import js_logo from '../../assets/img/Js_logo.png'
import Wordpress_logo from '../../assets/img/Wordpress_logo.png'
import react_logo from '../../assets/img/React_logo.png'
import flutter_logo from '../../assets/img/Flutter_logo.png'
import DemandeDevis from '../../composants/DemandeDevis/DemandeDevis'

const WebDev = ({Deviser}) => {
  return (
    <>
      <main>
        <section className="section1 container" style={{marginTop:'10rem'}}>

          <div className='blocText'>

            <h1 className='heading1' style={{color:'#E8C133'}}>
              La solution <br /> de Création <br /> de votre <span style={{color:'white'}}>site internet</span> 
            </h1>

            <p>
              Expertise globale internet, création de site vitrine, professionnel, e-commerce, Blog, newsletter personnalisée.
            </p>

            <DemandeDevis Deviser={Deviser}/>

          </div>

          <div className='blocImg'>
            <div>
              <img src={template_site} alt="Template site" />
            </div>

            <div>
              <img src={notation} alt="Notation" />
            </div>
            
          </div>
         
        </section>
        <section className="section2 container">
          <div className='Titre_bloc'>
            <span className='Titre'>Web</span> <br />
            <span>Design</span>
          </div>
          <div>
            <img src={roster_sites} alt="Roster Sites" />
          </div>

        </section>
        <section className="section3 container">
          <div>
            <img src={Figma_logo} alt="" />
          </div>
          <div>
            <h1 className='heading1'>Création sur <br /> mesure, <span>pour vous avec vous</span> </h1>
            <p>
            Et c’est justement parce que nous aimons le travail bien fait, que nous regardons au-delà de votre projet digital. Nous prenons en compte votre stratégie globale, votre environnement, vos objectifs, vos utilisateurs ou encore vos différentes parties-prenantes.
            </p>
          </div>

        </section>
        <section className="section4 container">
          <div id='Puce'>
            <img src={puce_spe} alt="Puce" />
          </div>
          <div className='container_ts'>
            <div className='bloc_type_site bloc'>

                <div>
                  <div className='Puce_invisible'>
                    <img src={puce_spe} alt="Puce" />
                  </div>
                  <h1 className='heading_span'>
                    à vos mesures <br />
                    Tous nos sites sont <br /> développés pour vous, <span>mais surtout pour vos utilisateurs.</span> 
                  </h1>
                </div>
               

                <div className='list_element zerotext'>
                  <span className='heading_span'>01</span>
                  <div>
                    <span className='heading_span site_type'>Professionnels</span>
                    <p>
                      La solution de création de site conçue pour tous les professionnels. dans la réussite de votre entreprise sur internet.
                      </p>
                  </div>
                </div>

                <div className='list_element untext'>
                  <span className='heading_span'>02</span>
                  <div>
                    <span className='heading_span site_type'>Associations</span>
                    <p>
                      Créez le site de votre association sans contraintes techniques. Confier nous votre besoin nous apportons la visibilité à votre projet.
                    </p>
                  </div>
                </div>

                <div className='list_element deuxtext'>
                  <span className='heading_span'>03</span>
                  <div>
                    <span className='heading_span site_type'>Particuliers</span>
                    <p>
                      Une idée, un projet, une envie. Nous sommes là pour vous aider à construire votre site personnel. Votre site, votre style.
                    </p>
                  </div>
                </div>

                <div className='list_element troistext'>
                  <span className='heading_span'>04</span>
                  <div>
                    <span className='heading_span site_type'>E-commerce</span>
                    <p>
                      Créez sereinement un site de qualité professionnelle pour une institution. Nous vous accompagnons dans la réalisation de votre site institutionnel.
                    </p>
                  </div>
                </div>

            </div>
              
            <div className='bloc'>

              <div className='zeroimg'>
                <img src={site1} alt="site 1" />
              </div>

              <div  className='unimg'>
                <img src={site2} alt="site 2" />
              </div>

              <div className='deuximg'>
                <img src={site3} alt="site 3" />
              </div>

              <div className='troisimg'>
                <img src={site4} alt="site 4" />
              </div>

            </div>
          </div>
        </section>
        <section className="section5 container">
          <div className='Titre_bloc' >
            <span className='Titre'>Dev</span> <br />
            <span >Solution</span>
          </div>
          <div className='Dev_bloc'>
            <div className='bloc'>
              <h1 className="heading1">
              Pour vous livrer <br /> <span>une  solution web performante.</span>
              </h1>
              <p>
                Nos techniciens ont du goût, et surtout pour les choses bien faites.
                Proposition d’une arborescence, révision des contenus, élaboration de maquettes, développement et intégration web, mise en ligne, optimisation finale, nous co-construisons votre solution digitale avec vous et pilotons toutes les étapes de conception de votre site internet, dans les moindre détails (parce que ça ne nous intéresse pas d’être simplement exécutants).
              </p>
            </div>
            <div className='bloc'>
              <img src={dev_sol_anime} alt="Dev Solution" />
            </div>
          </div>
          <div className='Card_lang_roster'>
            <div className='Card_lang'>
              <h1>
                HTLM 5, Js, <br />
                CCS3, PHP
              </h1>
              <span>

                Developpement

              </span>
              <div>
                <img src={html_logo} alt="" />
                <img src={js_logo} alt="" />
              </div>
            </div>
            <div className='Card_lang'>
              <h1>
                Wordpress
              </h1>
              <span>

                Developpement

              </span>
              <div>
                <img src={Wordpress_logo} alt="" />
              </div>
            </div>
            <div className='Card_lang'>
              <h1>
                React Native
              </h1>
              <span>

                Developpement

              </span>
              <div>
                <img src={react_logo} alt="" />
              </div>
            </div>
            <div className='Card_lang'>
              <h1>
                Flutter
              </h1>
              <span>

                Developpement

              </span>
              <div>
                <img src={flutter_logo} alt="" />
              </div>
            </div>
          </div>

        </section>
        <section className="section6 container">
          <div className='bloc'>
            <img src={puce_spe} alt="Puce" />
            <div>

              <h1 className="heading_span"> Mieux atteindre vos clients <br /> Créer une newsletter <span> gratuite & personnalisée </span>  tenez informés tous vos clients de vos dernières offres et actualités en créant une newsletter.
              </h1>

              <p>
                 Laissez Ornoir s’occuper des tâches rebarbatives sélectionnez l’un de nos modèles de newsletter professionnels, puis personnalisez le à l’image de votre marque ou selon vos envies. N'oubliez pas que si vous avez besoin d'un peu d'aide pour écrire, notre service vous accompagne à son intégration  Ensuite, utilisez nos designers incroyable simples pour personnaliser divers éléments de conception tels que les images, les polices et les couleurs. 
              </p>
            </div>
          </div>
          <div className='bloc'>
            <img src={newsletter_img} alt="" />
          </div>

        </section>
      </main>
      
    </>
    
  )
}

export default WebDev