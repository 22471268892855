import React, {createContext,useState} from 'react'





const Devis=createContext()

export const DevisContext=Devis

export const Deo=createContext('Deo')


export const DevisProvider=({children})=>{

  const [devis,openDevis]=useState('close')
  const [menu,openMenu]=useState('close')

  const object={devis:devis,menu:menu}

  return (
    <DevisContext.Provider value={object}>
      {children}
    </DevisContext.Provider>
  )
}





const UseDevis = () => {
  return (
    <>
        <Devis.Provider value='deo'>
            <Devis.Consumer>{value=>value}</Devis.Consumer>
        </Devis.Provider>
        <Devis.Consumer>{value=>value}</Devis.Consumer>
    </>
  )
}
export default UseDevis

