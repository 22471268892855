import React from 'react'
import './CGU.css'



const CGU = () => {

  return (




    <div id='CGU'>
      <div id='CGU_content'>
        <h1>Conditions Générales d'Utilisation du site</h1>

        <h2 className='CGU_content_h2'> Date de dernière mise à jour : [15/10/2024]</h2> 

        <h2 className='CGU_content_h2'>1. Objet </h2>
       
        <div>
          <p>         
          Les présentes Conditions Générales d'Utilisation (CGU) et de Vente (CGV) régissent l'accès, l'utilisation du site internet [Ornoir.net] (ci-après "le Site") ainsi que la vente des services et produits proposés sur le Site. En accédant au Site et en passant commande, l'utilisateur (ci-après "l'Utilisateur" ou "l'Acheteur") accepte les présentes conditions sans réserve.
          </p>

        </div>

        <h2 className='CGU_content_h2'>2.  Accès au Site</h2>
        
        <div>
          <p>
          Le Site est accessible gratuitement à tout Utilisateur disposant d'une connexion Internet. Toutefois, certains services peuvent nécessiter la création d’un compte utilisateur. Le Site est accessible 24h/24 et 7j/7, sauf en cas de force majeure ou de maintenance technique. [Ornoir.net] ne saurait être tenu responsable des interruptions d'accès.
          </p>

        </div>

        <h2 className='CGU_content_h2'>3. Services proposés</h2>
        
        <div>
          <p>
          Le Site [Ornoir.net] propose des services de transport urbain, de réservation de véhicules, des abonnements, ainsi que des produits dérivés. Les caractéristiques des services et produits sont décrites sur le Site de manière aussi précise que possible. [Ornoir.net] se réserve le droit de modifier ou d'ajouter des services à tout moment.
          </p>
        </div>

        <h2 className='CGU_content_h2'>4. Inscription et Compte Utilisateur</h2>
        
        <div>
          <p>
          Pour accéder à certaines fonctionnalités, l'Utilisateur doit créer un compte en fournissant des informations exactes et à jour. L'Utilisateur est seul responsable de la confidentialité de ses identifiants et de l'usage de son compte. Toute utilisation non autorisée de son compte doit être signalée immédiatement à [Ornoir.net].
          </p>
        </div>

        <h2 className='CGU_content_h2'>5. Utilisation du Site</h2>
        <div>
          <p>
          L'Utilisateur s'engage à utiliser le Site de manière légale et à ne pas porter atteinte à l'intégrité, la sécurité ou le bon fonctionnement du Site. Toute activité frauduleuse, illégale ou contraire à la réglementation en vigueur est interdite.
          </p>
        </div>

        <h2 className='CGU_content_h2'>6. Propriété Intellectuelle</h2>
        <div>
          <p>
          Tous les contenus présents sur le Site (textes, images, logos, etc.) sont la propriété exclusive de [Ornoir.net]. Toute reproduction ou exploitation non autorisée de ces contenus est strictement interdite.
          </p>
        </div>

        <h2 className='CGU_content_h2'>7. Protection des Données Personnelles </h2>
        <div>
          <p>
          Les données personnelles collectées sur le Site sont traitées conformément à la [Politique de confidentialité] de [Ornoir.net], disponible sur le Site. L’Utilisateur accepte que ses données soient utilisées pour les finalités liées à la gestion des commandes et des services proposés.
          </p>
        </div>

        <h2 className='CGU_content_h2'>8. Conditions de Vente en Ligne</h2>
        <h3> 8.1. Commandes</h3>
        <div>
          <p>
            Pour passer commande, l'Acheteur doit suivre les étapes suivantes :
          </p>
        
            1.	Sélection des produits/services,<br />
            2.	Validation du panier,<br />
            3.	Création ou identification du compte,<br />
            4.	Choix du mode de livraison et de paiement,<br />
            5.	Confirmation de la commande.

            <p>
                Toute commande validée constitue un engagement ferme de l’Acheteur. Une confirmation de commande sera envoyée par email.
            </p>

        </div>
        <h3> 8.2. Prix et Paiements</h3>
        <div>
          <p>
            Les prix des services et produits sont indiqués en [monnaie locale] et incluent toutes les taxes applicables. [Ornoir.net] se réserve le droit de modifier ses prix à tout moment, mais les produits/services seront facturés au tarif en vigueur au moment de la commande. <br />
            Les paiements peuvent être effectués via les méthodes suivantes :
          </p>
        
           •	Cartes bancaires (Visa, MasterCard, etc.), <br />
           •	Mobile Money, <br />
            •	Virement bancaire. <br />

            <p>
              Les transactions sont sécurisées par un protocole SSL. [Ornoir.net] ne conserve aucune information bancaire des utilisateurs.
            </p>

        </div>

        <h3>8.3. Livraison des Services et Produits</h3>
        <div>
          <p>
            Les services sont généralement disponibles immédiatement après confirmation du paiement. En cas de vente de produits physiques, les délais de livraison varient en fonction de la zone géographique et seront indiqués lors de la commande. Les frais de livraison seront également précisés avant validation de la commande.
          </p>

        </div>

        <h3>8.4. Droit de Rétractation et Retour</h3>
        <div>
          <p>
            L'Acheteur dispose d'un délai de 10 jours pour exercer son droit de rétractation à compter de la réception des produits. Ce droit ne s'applique pas aux services pleinement exécutés ou aux produits personnalisés.
          </p>
          <p>
            Les produits retournés doivent être en parfait état et dans leur emballage d'origine. Les frais de retour sont à la charge de l'Acheteur, sauf en cas de non-conformité ou de produit défectueux. Le remboursement sera effectué sous 30 jours après réception des produits retournés.
          </p>
        </div>

        <h2 className='CGU_content_h2'>9. Garanties et Réclamations</h2>
        <h3> 9.1. Garanties Légales</h3>
        <div>
          <p>
            Tous les produits vendus sur le Site bénéficient des garanties légales de conformité et contre les vices cachés. Si un produit ou service présente un défaut, l’Acheteur peut adresser une réclamation à contact@ornoir.net.
          </p>

        </div>

        <h3> 9.2. Réclamations</h3>
        <div>
          <p>
            Toute réclamation doit être faite par email à ornoir@contact.net ou par WhatsApp au +242 06 907 9797. <span style={{fontWeight:900}}>Ornoir Agency </span> s’engage à répondre dans les meilleurs délais et à apporter une solution satisfaisante.
            
          </p>

        </div>

        <h2 className='CGU_content_h2'>10. Responsabilité</h2>
        <h3> 10.1. Limitation de Responsabilité</h3>
        <div>
          <p>
            <span style={{fontWeight:900}}>Ornoir Agency </span>  ne saurait être tenu responsable des dommages directs ou indirects résultant de l’utilisation du Site, de la perte de données, ou de tout autre problème lié à l'accès ou à l'utilisation du Site.
          </p>

        </div>

        <h3> 10.2. Force Majeure</h3>
        <div>
          <p>
            <span style={{fontWeight:900}}>Ornoir Agency </span>  ne pourra être tenu responsable de tout retard ou non-exécution de ses obligations en cas de force majeure (catastrophes naturelles, grèves, pannes techniques, etc.).
          </p>

        </div>

        <h2 className='CGU_content_h2'>11. Liens Hypertextes</h2>
        <p>
        Le Site peut contenir des liens vers des sites tiers. <span style={{fontWeight:900}}>Ornoir Agency </span> décline toute responsabilité quant au contenu ou à l'utilisation de ces sites externes.
        </p>

        <h2 className="CGU_content_h2">12. Modification des CGU et CGV</h2>
        <p>
          <span style={{fontWeight:900}}>Ornoir Agency</span> se réserve le droit de modifier   à tout moment les présentes CGU/CGV. Toute modification sera publiée sur le Site et prendra effet immédiatement. L'Utilisateur est invité à consulter régulièrement ces conditions pour rester informé des mises à jour.
        </p>

        <h2 className="CGU_content_h2">13. Loi Applicable et Juridiction Compétente</h2>
        <p>
          Les présentes CGU/CGV sont régies par les lois de la République Congo. En cas de litige, les tribunaux de Brazzaville seront seuls compétents.
        </p>

        <h2 className="CGU_content_h2">14. Contact</h2>
        <p>
          Pour toute question relative aux présentes CGU/CGV ou à l'utilisation du Site, l'Utilisateur peut contacter <span style={{fontWeight:900}}>Ornoir Agency</span> à l’adresse suivante : <br />

          <span style={{fontWeight:900}}>Email :</span>contact@ornoir.net <br />
          <span style={{fontWeight:900}}>Adresse :</span>19 rue Nkou Kinsounde - Mfilou <br />

        </p>

      </div>
    
    </div>
   
  )
}

export default CGU