import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';

import Footer from './composants/Footer/Footer';
import Navbar from './composants/Navbar/Navbar';

import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Market from './pages/Market/Market';
import GraphiquePrint from './pages/GraphiquePrint/GraphiquePrint';
import WebDev from './pages/WebDev/WebDev';
import AppDev from './pages/AppDev/AppDev';
import DesignProduit from './pages/DesignProduit/DesignProduit'
import Animation from './pages/Animation/Animation';


import DPC from './pages/Politiques/DPC/DPC';
import ML from './pages/Politiques/ML/ML'
import CGU from './pages/Politiques/CGU/CGU'



import EnTravaux from './pages/EnTravaux/EnTravaux';
import Sandbox from './pages/Sandbox/Sandbox';
import ErrorPage from './pages/ErrorPage/ErrorPage';


import {useState } from 'react';

import {  DevisProvider } from './hook/UseDevis';




function App() {

  const [showNavbar, setshowNavbar]=useState(true)
  function TogOn() {

      setshowNavbar(true)
  
  }
  function TogOff() {

      setshowNavbar(false)
    
      
  }
 

  const [devis, setDevis] = useState(false)  

  function topAnchor () {
      document.getElementById('Top_page').scrollIntoView({behavior:'smooth'})
  }
  function Deviser() {


      if (devis) {
        setDevis(false)
        topAnchor()
        
      } else {

        setDevis(true)
        topAnchor()
        
      }
  
  }

  


  return (
      <>
       
        <DevisProvider > 

       
          <BrowserRouter>
        
              <div id='Top_page'  style={{width:'100%',height:'10px'}}></div>

              <Navbar Devis={devis}/>         

              <Routes> 
                
                <Route path='/Sandbox' element={<Sandbox/>}/>
                <Route path='*' element={<ErrorPage/>}/>

                <Route path='/EnTravaux' element={<EnTravaux/>}/>
                
                <Route path='/' element={<Home Deviser={Deviser}/>}/>
                <Route path='/Services' element={<Services/>}/>
                <Route path='/Market' element={<Market/>}/>
                <Route path='/GraphiquePrint' element={<GraphiquePrint Deviser={Deviser}/>}/>
                <Route path='/WebDev' element={<WebDev Deviser={Deviser}/>}/>
                <Route path='/AppDev' element={<AppDev Deviser={Deviser}/>}/>
                <Route path='/DesignProduit' element={<EnTravaux/>}/>
                <Route path='/Animation' element={<EnTravaux/>}/>

                <Route path='/CGU' element={<CGU/>}/>
                <Route path='/DPC' element={<DPC/>}/>
                <Route path='/ML' element={<ML/>}/>

              </Routes>
              
              <Footer Anchor={topAnchor} />
        
          </BrowserRouter>

       
        </DevisProvider>

    </>

  
  )
}

export default App;
