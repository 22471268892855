import React from 'react'
import './AppDev.css'

import Dev_mobile from '../../assets/img/Dev_mobile.png'
import puce from '../../assets/icons/Puce_spe.svg'

import ideation1 from '../../assets/img/Ideation_1.png'
import ideation2 from '../../assets/img/Ideation_2.png'
import ideation3 from '../../assets/img/Ideation_3.png'
import ideation4 from '../../assets/img/Ideation_4.png'

import CardDepliable, { Des_p, Dev_p, Product_p } from '../../composants/CardDepliable/CardDepliable'
import icon_design from '../../assets/icons_btn/design_puce.png'
import icon_dev from '../../assets/icons_btn/Dev_puce.png'
import icon_pro from '../../assets/icons_btn/Product_puce.png'
import Placeholder from '../../assets/img/Placeholder.png'

import Chacks from '../../assets/img/Chacks.png'


import Design from '../../assets/img/Design.svg'
import Dev from '../../assets/img/Dev.svg'
import Product from '../../assets/img/Product.svg'
import DemandeDevis from '../../composants/DemandeDevis/DemandeDevis'

const AppDev = ({Deviser}) => {


  return (
    <>
    <main>
      <section className='container'>
        <div className='App_dev_titre'>

          <span>Dev</span>
          <span>Mobile</span>

        </div>
        <div className='Dev_mobile'>

          <div className="bloc">

            <span>
              Créateur  <br /> d’applications
            </span>
            <span>
              web & mobiles 
            </span>
            <span>
              sur mesure
            </span>

            <p>
              Nous investissons sur les personnes qui construisent les applications. L'ensemble de nos développements reposent sur des règles de qualité (revue de code, tests automatisés, CI/CD) assurant des développements durables dans le temps.
            </p>

            <DemandeDevis Deviser={Deviser}/>

          </div>

          <div className="bloc">
            <img src={Dev_mobile} alt="App Developpés" />
          </div>
        </div>
      </section>
      <section className='container'>
        <div className='heading_section'>
        
          <span> Votre application mérite </span>
          <span>de vrais experts</span>

        </div>
        <div id='ideation'>
          <div className='blochalf'>
            <div>
              <img src={puce} alt="" />
            </div>
            <div className='step'>
              <div>
                <span>
                  Les étapes de conception, de création  
                </span>
                <span>
                   de votre d’application web ou mobile
                </span>
              </div>
              <p>
               Nous avons construit des étapes pertinentes pour élaborer le produit innovant qui vous ressemble.
              </p>
            </div>
          </div>
          <div className='blochalf ide'>
            <img src={ideation1} alt="" />
            <div>
              <span>Idéation</span>
              <p>
                valider la proposition de valeur de votre idée
              </p>
            </div>
            
          </div>
          <div className='blochalf ide'>
            <img src={ideation2} alt="" />
            <div>
              <span>Conception</span>
              <p>
                designer et prototyper votre application avec vos futurs utilisateurs
              </p>
            </div>
          </div>
          <div className='blochalf ide'>
            <img src={ideation3} alt="" />
            <div>
              <span>Développement</span>
              <p>
                valider la proposition de valeur de votre idée

              </p>
            </div>
          </div>
          <div className='bloc100 ide'>
            <img src={ideation4} alt="" />
            <div>
              <span>Maintenance <br /> Évolutions <br /> Analys data</span>
              <p>
                Puis nous vous accompagnons pour assurer le succès de votre application !
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container">

        <div className='heading_section excellence'>
          <span>L'excellence,</span>
          <span>notre maitre mot</span>
          <p>
            Que ce soit en matière de code, de design ou de produit, notre multi expertise incarnée par des talents ultra qualifiés, est le pilier central de notre vision.
          </p>
        </div>
       

        <div className='maitre_mot'>
          <div className='bloc'>

            <CardDepliable icon_card={icon_design} CardDepliable_titre={'Design'} paragraph_text={Des_p}/>

            <CardDepliable icon_card={icon_dev} CardDepliable_titre={'Developpement'} paragraph_text={Dev_p}/>

            <CardDepliable icon_card={icon_pro} CardDepliable_titre={'Produit'} paragraph_text={Product_p}/>

            
          </div>

          <div className='bloc Card'>

            <img id='Placeholder' src={Placeholder} alt="Excellence" /> 
             
            <img className='roster_img img1' src={Design} alt="Design" />

            <img className='roster_img img2' src={Dev} alt="Dev" />

            <img className='roster_img img3' src={Product} alt="Product" /> 

           

            
           
           
            
            
          </div>
        </div>

      </section>
      <section className="container">
        <div className='construisons'> 

          <div className='construisons_heading'>
            <span> Construisons votre </span>
            <span>application mobile</span>
            <span> sur mesure</span>
          </div>
          
          <p>
            Confiez nous la création de votre application mobile à une agence proche de ses clients qui s’engage à vos côtés, pour vous aider à sortir une application mobile intuitive et engageante.
          </p>
          
          <div>
            <img src={Chacks} alt="Chacks App" />
          </div>

        </div>
      </section>
      <section className="container section_BG">
        <h1>Lançons nous ensemble</h1>

        <div onClick={Deviser}>Discutons de votre projet</div>
      </section>
    </main>
    </>
    
  )
}

export default AppDev