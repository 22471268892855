import React from 'react'
import './ML.css'

const ML = () => {
  return (
    <>
    <div id='ML'>
      <div className='Centercontent'>
        <h1>Mentions légales</h1>
      
        <h2> Ornoir Agency</h2>
 
        <h2>Raison sociale :</h2>

        <div>
        Ets. Ornoir Agency <br/>
        Ets.  au capital de 200 000 FCFA<br/>
          <span>RCCM : </span> CG-BZV-01-2019-A10-00813 <br/>
          <span>NIU : </span> P2019110006521188
        </div>

        <h2> Siège social : </h2>
        <div>
        19 Rue Nkoua Kinsoudi Nzoko <br/>
          Brazzaville République du Congo <br/>
          Avenue de l’indépendance cinq chemins Q Mpaka  <br/>
          Pointe-Noire République du Congo <br/>
          <span>Email : </span> contact@ornoir.net | <span>Tél. </span> +242 06 519 1424
        </div>
      </div>
    </div>
    </>
  )


}

export default ML