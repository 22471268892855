import React from 'react'


const DemandeDevis = ({Deviser}) => {

 

  return (
    <>

      <div id='DevisButton' onClick={Deviser}>Demandez un devis</div>
      
 
    </> 
  )
}




export default DemandeDevis