import React, { useContext } from 'react'
import './Home.css'

import agence360 from '../../assets/img/agence-360.png'

import icons_creaweb from '../../assets/icons_btn/Web.png'
import icons_info from '../../assets/icons_btn/Infographie.png'
import icons_print from '../../assets/icons_btn/Print.png'
import icons_app from '../../assets/icons_btn/App_mobile.png'
import icons_anim from '../../assets/icons_btn/Anim2D.png'
import icons_design from '../../assets/icons_btn/Design Industriel.png'
import Membre from '../../composants/Membre/Membre'
import Orboy from '../../assets/img/Orboy.png'
import App from '../../assets/img/App.png'
import Web from '../../assets/img/Web.png'
import Info from '../../assets/img/Info.png'
import Print from '../../assets/img/Print.png'
import Anim from '../../assets/img/Anim.png'
import Design from '../../assets/img/Design.png'
import Team from '../../assets/img/Frame.png'

/*Pictures membre*/
import Profil from '../../assets/img/Profil.png'
import Jeff from '../../assets/img/Profils/Jeff.png'
import Geda from '../../assets/img/Profils/Geda.png'
import Ndeo from '../../assets/img/Profils/Ndeo.png'
import Rusty from '../../assets/img/Profils/Rusty.png'
import Yves from '../../assets/img/Profils/Yves.png'
import Dalvy from '../../assets/img/Profils/Dalvy.png'
import Nach from '../../assets/img/Profils/Nach.png'
import Christian from '../../assets/img/Profils/Christian.png'
import DemandeDevis from '../../composants/DemandeDevis/DemandeDevis'


const Home = ({Deviser}) => {

 

  return (
    <>
   
    <main id='HomePage'>
      <section id='Homesection1'  >
        < div id="centre" className='container'> 

          <div id='agence360'>
            <img src={agence360} alt="Agence 360" />
          </div>

          <div id='divparagraph'>
            <p className='paragraphe'>
              Via des stratégies Online et Offline, nous accompagnons les entreprises et les institutions vers          de nouveaux challenges, pour leur développement et la promotion de produits et services. 
            </p>
            <p className='paragraphe'>
              Après un Audit, notre démarche consiste à concevoir une stratégie globale de communication sur mesure, assurer les opérations de mise en œuvre et veiller à l’atteinte des objectifs visés.
            </p>
            <p className='paragraphe'>
              Les experts de Ornoir vous boostent vers la croissance et vous positionnent comme leader dans votre domaine d’activité, grâce à la valorisation permanente de votre image de marque.
            </p>
          </div>
         
           <DemandeDevis Deviser={Deviser} />
          <div id="BarreLink">
            <div><a href="#Web"><img src={icons_creaweb} alt="icons_creaweb" /></a></div><span className="divider"></span>
            <div><a href="#App"><img src={icons_app} alt="icons_app" /></a></div><span className="divider skewX"></span>
            <div><a href="#Info"><img src={icons_info} alt="icons_info" /></a></div><span className="divider"></span>
            <div><a href="#Print"><img src={icons_print} alt="icons_print" /></a></div><span className="divider"></span>
            <div><a href="#Anim"><img src={icons_anim} alt="icons_anim" /></a></div><span className="divider skewX5p"></span>
            <div><a href="#Design"><img src={icons_design} alt="icons_design" /></a></div>
          </div>
        </div>
      </section> 

     <section id="Homesection2" className='container'>
        <div className='bloc'>
          <span className='heading_span'>Qui sommes-nous ?</span>
          <h1 className='heading1'>L’Agence <br />de communication <br />Ornoir Agency</h1>
          <div>
            <p>
            Ornoir Agency est une agence de communication digitale basée à Brazzaville en république du Congo. Avec plus de 5 ans d’expérience, nous accompagnons les entreprises (TPE & PME) et les institutions dans l’atteinte de leurs objectifs.
            </p>
            <p>
            Grâce notre regard stratégique, notre œil graphique et nos compétences technologiques qualifiées, Nous utilisons les meilleurs outils de communications pour augmenter efficacement la présence de votre image de marque.
            </p>
            <p>
            Chez Ornoir Agency, nous nous impliquons personnellement à l’aboutissement de vos projets à la mesure de vos ambitions.
            </p>
          </div>
        </div>
        <div className='bloc'>
          <img src={Orboy} alt="Orboy" />
        </div>
      </section>

      <section id="Homesection3" className='container'>
        <span className='heading_span'>Ce que nous faisons</span>
        <h1 className='heading1'>Nos offres & services <br />en communication <br />digital et publicitaire</h1>
        <div id='Services'>
          <div id='Info'><img src={Info} alt="Info" /></div>
          <div id='App'><img src={App} alt="App" /></div>
          <div id='Web'><img src={Web} alt="Web" /></div>
          <div id='Print'><img src={Print} alt="Print" /></div>
          <div id='Anim'><img src={Anim} alt="Anim" /></div>
          <div id='Design'><img src={Design} alt="Design" /></div>
        </div>
      </section>

      <section id="Homesection4" className='container'>
        <div className='bloc un'>
          <span className='heading_span'>La team Ornoir</span>
          <h1 className='heading1'>Notre crédo Créativité <br />Innovation, Réactivité</h1>
          <p>Que votre objectif soit de créer une notoriété de marque, d’établir un positionnement unique sur le marché ou encore de lancer un nouveau produit. Notre équipe se montre créative, innovante et réactive pour vous aider à atteindre vos objectifs.</p>
        </div>
        <div className='bloc deux'>
          <img className='imgperso' src={Team} alt="La team" />
        </div>
      </section>

      <section id="Homesection5" className='container'>

        <Membre Profil={Jeff}ProfilPic={Profil} NomPrenom={ 'Jeff Malonga'} fonction={'Fondateur / Chef de projet'}></Membre>
        <Membre Profil={Geda}ProfilPic={Profil} NomPrenom={'Geda Mant\'s'} fonction={'Developpeur Mobile et Web FullStack'}></Membre>
        <Membre Profil={Ndeo}ProfilPic={Profil} NomPrenom={'Deo Gracias'} fonction={'Coordonnateur technique / Animateur 2D et 3D '}></Membre>
        <Membre Profil={Rusty}ProfilPic={Profil} NomPrenom={'Crepsy Mbemba'} fonction={'Coordonnateur administratif et financier, chargé des relations extérieures.'}></Membre>
        <Membre Profil={Dalvy}ProfilPic={Profil} NomPrenom={'Dalvy Blest'} fonction={'Graphique Designer'}></Membre>
        <Membre Profil={Nach}ProfilPic={Profil} NomPrenom={'Nachguel Mitekele'} fonction={'IT responsable technique et gestion parc Informatique'}></Membre>
        <Membre Profil={Yves}ProfilPic={Profil} NomPrenom={'Yves Ebah'} fonction={'Consultant expert en marketing et communication'}></Membre>
        <Membre Profil={Christian}ProfilPic={Profil} NomPrenom={'Christian Ngouala'} fonction={'Produit Designer'}></Membre>
        
      </section>

      <section id="Homesection6" className='container'>
      
        <div id='Homesection6Div' >
            <div>
              100 % agile
            </div>
            <div>
              Parce que votre entreprise est unique, nous construisons une équipe à partir de votre projet et non l’inverse. Nous nous engageons à comprendre votre écosystème, vos enjeux et vos attentes pour vous proposer une communication sur mesure et cultiver votre différence.
            </div>
        </div>
        
      </section>
    </main>
   
  </>
    
  )
}

export default Home